import permissions from 'constants/permission';
import React from 'react';

const menu = [
  {
    label: 'Trang chủ',
    key: 'trangchu',
    icon: <i className="icon-sidebar itt-home" />,
    path: '/',
    activepaths: ['/', '/huong-dan-su-dung'],
  },
  {
    label: 'Tiếp công dân',
    key: 'tiepcongdan',
    icon: <i className="icon-sidebar itt-document" />,
    activepaths: [
      '/tiep-cong-dan-thuong-xuyen',
      '/tiep-cong-dan/dinh-ky',
      '/tiep-cong-dan/dot-xuat',
      '/tiep-cong-dan/dang-ky-gap-lanh-dao',
      '/tiep-cong-dan-thuong-xuyen/them-moi',
      '/tiep-cong-dan-thuong-xuyen/chi-tiet',
      '/tiep-cong-dan/dinh-ky/them-moi',
      '/tiep-cong-dan/dinh-ky/chi-tiet',
      '/tiep-cong-dan/dot-xuat/chi-tiet',
      '/tiep-cong-dan/dot-xuat/them-moi',
      '/tiep-cong-dan/dang-ky-gap-lanh-dao/chi-tiet',
      '/tiep-cong-dan/dang-ky-gap-lanh-dao/them-moi'
    ],
    permissions: permissions.TIEPCONGDAN,
    children: [
      {
        label: 'Sổ tiếp công dân thường xuyên',
        key: 'tcdthuongxuyen',
        path: '/tiep-cong-dan-thuong-xuyen',
        activepaths: [
          '/tiep-cong-dan-thuong-xuyen',
          '/tiep-cong-dan-thuong-xuyen/them-moi',
          '/tiep-cong-dan-thuong-xuyen/chi-tiet',
        ],
        permissions: permissions.TIEPCONGDAN_XEM_DANH_SACH
      },
      {
        label: 'Sổ tiếp công dân định kỳ của lãnh đạo',
        key: 'tcddinhky',
        path: '/tiep-cong-dan/dinh-ky',
        activepaths: [
          '/tiep-cong-dan/dinh-ky',
          '/tiep-cong-dan/dinh-ky/them-moi',
          '/tiep-cong-dan/dinh-ky/chi-tiet',
        ],
        permissions: permissions.TIEPCONGDAN_XEM_DANH_SACH
      },
      {
        label: 'Sổ tiếp công dân đột xuất của lãnh đạo',
        key: 'tcddotxuat',
        path: '/tiep-cong-dan/dot-xuat',
        activepaths: [
          '/tiep-cong-dan/dot-xuat',
          '/tiep-cong-dan/dot-xuat/chi-tiet',
          '/tiep-cong-dan/dot-xuat/them-moi'
        ],
        permissions: permissions.TIEPCONGDAN_XEM_DANH_SACH
      },
      {
        label: 'Danh sách đăng ký gặp lãnh đạo',
        key: 'dkgaplanhdao',
        path: '/tiep-cong-dan/dang-ky-gap-lanh-dao',
        activepaths: [
          '/tiep-cong-dan/dang-ky-gap-lanh-dao',
          '/tiep-cong-dan/dang-ky-gap-lanh-dao/chi-tiet',
          '/tiep-cong-dan/dang-ky-gap-lanh-dao/them-moi'
        ],
        permissions: permissions.TIEPCONGDAN_DANG_KY_GAP_LD
      },
    ],
  },
  {
    label: 'Xử lý / Giải quyết đơn',
    key: 'xulydon',
    icon: <i className="icon-sidebar itt-file" />,
    path: '/xu-ly-giai-quyet-don',
    permissions: permissions.XULYGIAIQUYET_XEM_DANH_SACH,
    activepaths: [
      '/xu-ly-giai-quyet-don',
      '/xu-ly-giai-quyet-don/them-moi',
      '/xu-ly-giai-quyet-don/chi-tiet',
    ],
  },
  {
    label: 'Theo dõi thực hiện giải quyết đơn',
    key: 'theodoithuchien',
    icon: <i className="icon-sidebar itt-contract" />,
    path: '/theo-doi-thuc-hien-giai-quyet-don',
    permissions: permissions.THEODOITHUCHIEN_XEM_DANH_SACH,
    activepaths: [
      '/theo-doi-thuc-hien-giai-quyet-don',
      '/theo-doi-thuc-hien-giai-quyet-don/:id'
    ],
  },
  {
    label: 'Thanh tra',
    key: 'thanhtra',
    icon: <i className="icon-sidebar itt-file" />,
    path: '/thanh-tra',
    permissions: permissions.THONGKEBAOCAO_THANH_TRA,
    activepaths: [
      '/thanh-tra',
    ],
  },
  {
    label: 'Tổng hợp báo cáo',
    key: 'tonghopbaocao',
    icon: <i className="icon-sidebar itt-pie-chart" />,
    activepaths: [
      '/tkbc-giai-quyet-khieu-nai-thuoc-tham-quyen',
      '/tkbc-xu-ly-don',
      '/tkbc-tiep-cong-dan-thuong-xuyen',
      '/tkbc-xu-ly-don-khieu-nai',
      '/tkbc-xu-ly-don-to-cao',
      '/tkbc-phan-loai-xu-ly-qua-tiep-cong-dan',
      '/tkbc-xu-ly-don-kien-nghi',
      '/tkbc-thi-hanh-quyet-dinh-giai-quyet-khieu-nai',
      '/tkbc-giai-quyet-to-cao-thuoc-tham-quyen',
      '/tkbc-thuc-hien-ket-luan-noi-dung-bao-cao',
      '/tkbc-bao-cao-so-sanh-tinh-hinh-xu-ly-don-qua-tung-nam',
      '/tkbc-cong-tac-tiep-cong-dan-va-xu-ly-don'
    ],
    permissions: permissions.THONGKEBAOCAO,
    children: [
      {
        label: 'Tổng hợp kết quả tiếp công dân thường xuyên, định kỳ và đột xuất',
        key: 'TkbcTiepCongDanThuongXuyen',
        path: '/tkbc-tiep-cong-dan-thuong-xuyen',
        activepaths: ['/tkbc-tiep-cong-dan-thuong-xuyen'],
        permissions: permissions.THONGKEBAOCAO_TIEP_CONG_DAN
      },
      {
        label: 'Tổng hợp kết quả phân loại, xử lý đơn qua tiếp công dân',
        key: 'tkbcphanloaixulydonquatiepcongdan',
        path: '/tkbc-phan-loai-xu-ly-qua-tiep-cong-dan',
        activepaths: ['/tkbc-phan-loai-xu-ly-qua-tiep-cong-dan'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON
      },
      {
        label: 'Tổng hợp kết quả xử lý đơn',
        key: 'tkbcxulydon',
        path: '/tkbc-xu-ly-don',
        activepaths: ['/tkbc-xu-ly-don'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON
      },
      {
        label: 'Tổng hợp kết quả xử lý đơn khiếu nại',
        key: 'TkbcXuLyDonKhieuNai',
        path: '/tkbc-xu-ly-don-khieu-nai',
        activepaths: ['/tkbc-xu-ly-don-khieu-nai'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON
      },
      {
        label: 'Tổng hợp kết quả xử lý đơn tố cáo',
        key: 'TkbcXuLyDonToCao',
        path: '/tkbc-xu-ly-don-to-cao',
        activepaths: ['/tkbc-xu-ly-don-to-cao'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON
      },
      {
        label: 'Tổng hợp kết quả xử lý đơn kiến nghị, phản ánh',
        key: 'TkbcXuLyDonKienNghi',
        path: '/tkbc-xu-ly-don-kien-nghi',
        activepaths: ['/tkbc-xu-ly-don-kien-nghi'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON
      },
      {
        label: 'Tổng hợp kết quả giải quyết khiếu nại thuộc thầm quyền',
        key: 'tkbcgiaiquyetkhieunaithuocthamthamquyen',
        path: '/tkbc-giai-quyet-khieu-nai-thuoc-tham-quyen',
        activepaths: ['/tkbc-giai-quyet-khieu-nai-thuoc-tham-quyen'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON
      },
      {
        label: 'Tổng hợp kết quả thi hành quyết định giải quyết khiếu nại',
        key: 'TkbcThiHanhQuyetDinhGiaiQuyet',
        path: '/tkbc-thi-hanh-quyet-dinh-giai-quyet-khieu-nai',
        activepaths: ['/tkbc-thi-hanh-quyet-dinh-giai-quyet-khieu-nai'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON
      },
      {
        label: 'Tổng hợp kết quả giải quyết tố cáo thuộc thẩm quyền',
        key: 'TkbcGiaiQuyetToCaoThuocThamQuyen',
        path: '/tkbc-giai-quyet-to-cao-thuoc-tham-quyen',
        activepaths: ['/tkbc-giai-quyet-to-cao-thuoc-tham-quyen'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON
      },
      {
        label: 'Tổng hợp kết quả thực hiện kết luận nội dung tố cáo',
        key: 'tkbcthuchiennoidungketluantocao',
        path: '/tkbc-thuc-hien-ket-luan-noi-dung-bao-cao',
        activepaths: ['/tkbc-thuc-hien-ket-luan-noi-dung-bao-cao'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON
      },
      {
        label: 'Báo cáo công tác tiếp công dân và xử lý đơn',
        key: 'baocaotiepcongdanvaxulydon',
        path: '/tkbc-cong-tac-tiep-cong-dan-va-xu-ly-don',
        activepaths: ['/tkbc-cong-tac-tiep-cong-dan-va-xu-ly-don'],
        permissions: permissions.THONGKEBAOCAO_TIEP_CONG_DAN_VA_XU_LY_DON
      },
      {
        label: 'Báo cáo thống kê so sánh tình hình xử lý đơn qua các năm',
        key: 'baocaothongkesosanhtinhhinhxulydonquacacnam',
        path: '/tkbc-bao-cao-so-sanh-tinh-hinh-xu-ly-don-qua-tung-nam',
        activepaths: ['/tkbc-bao-cao-so-sanh-tinh-hinh-xu-ly-don-qua-tung-nam'],
        permissions: permissions.THONGKEBAOCAO_XU_LY_DON_QUA_CAC_NAM
      },
    ],
  },
  {
    label: 'Theo dõi và giám sát',
    key: 'theodoigiamsat',
    icon: <i className="icon-sidebar itt-magnifying-glass" />,
    activepaths: ['/theo-doi-xu-ly-giai-quyet-don', '/theo-doi-dinh-ky', '/thong-ke-don-cu', '/thong-ke-don-cu/ho-so-vu-viec'],
    permissions: permissions.THEODOIGIAMSAT,
    children: [
      {
        label: 'Theo dõi theo xử lý giải quyết đơn',
        key: 'theodoixulygiaiquyet',
        path: '/theo-doi-xu-ly-giai-quyet-don',
        activepaths: ['/theo-doi-xu-ly-giai-quyet-don'],
        permissions: permissions.THEODOIGIAMSAT_XU_LY_GIAI_QUYET_DON,
      },
      {
        label: 'Theo dõi theo kỳ',
        key: 'theodoidinhky',
        path: '/theo-doi-dinh-ky',
        activepaths: ['/theo-doi-dinh-ky'],
        permissions: permissions.THEODOIGIAMSAT_THEO_KY
      },
      {
        label: 'Thống kê đơn cũ',
        key: 'thongkedoncu',
        path: '/thong-ke-don-cu',
        activepaths: ['/thong-ke-don-cu'],
        permissions: permissions.THEODOIGIAMSAT_THONG_KE_DON_CU
      },
      {
        label: 'Theo dõi theo nguồn tiếp nhận',
        key: 'theodoitheonguontiepnhan',
        path: '/theo-doi-theo-nguon-tiep-nhan',
        activepaths: ['/theo-doi-theo-nguon-tiep-nhan'],
        permissions: permissions.THEODOIGIAMSAT_XU_LY_DON_THEO_NGUON
      },
    ],
  },
  {
    label: 'Tra cứu đơn',
    key: 'tracuudon',
    icon: <i className="icon-sidebar itt-file-search" />,
    path: '/tra-cuu-don',
    activepaths: ['/tra-cuu-don'],
    permissions: permissions.TRACUU_XEM_DANH_SACH,
  },
  {
    label: 'Quản trị hệ thống',
    key: 'quantrihethong',
    icon: <i className="icon-sidebar itt-setting" />,
    activepaths: [
      '/danh-muc-vai-tro',
      '/phan-quyen-vai-tro',
      '/danh-muc-chuc-vu',
      '/quan-ly-nguoi-dung',
      '/danh-muc-co-quan-quan-ly',
      '/danh-muc-co-quan-quan-ly',
      '/danh-muc-co-quan-quan-ly/them-moi',
      '/danh-muc-co-quan-quan-ly/chi-tiet/:id',
      '/danh-muc-co-quan-quan-ly/them-moi-cap-con/:id',
    ],
    permissions: ['NGUOIDUNG', 'VAITRO', 'CHUCDANH', 'QUYEN', 'SUPERADMIN'],
    children: [
      {
        label: 'Cơ quan quản lý',
        key: 'coquanquanly',
        path: '/danh-muc-co-quan-quan-ly',
        activepaths: [
          '/danh-muc-co-quan-quan-ly',
          '/danh-muc-co-quan-quan-ly/them-moi',
          '/danh-muc-co-quan-quan-ly/chi-tiet/:id',
          '/danh-muc-co-quan-quan-ly/them-moi-cap-con/:id',
        ],
        permissions: permissions.COQUANQUANLY_XEM_DANH_SACH
      },
      {
        label: 'Danh mục vai trò',
        key: 'danhmucvaitro',
        path: '/danh-muc-vai-tro',
        activepaths: ['/danh-muc-vai-tro'],
        permissions: permissions.VAITRO_XEM_DANH_SACH,
      },
      {
        label: 'Phân quyền vai trò',
        key: 'phanquyenvaitro',
        path: '/phan-quyen-vai-tro',
        activepaths: ['/phan-quyen-vai-tro'],
        permissions: permissions.QUYEN_XEM_DANH_SACH
      },
      {
        label: 'Danh mục chức vụ',
        key: 'chucvu',
        path: '/danh-muc-chuc-vu',
        activepaths: ['/danh-muc-chuc-vu'],
        permissions: ['NGUOIDUNG', 'SUPERADMIN'],
      },
      {
        label: 'Quản lý người dùng',
        key: 'QuanLyNguoiDung',
        path: '/quan-ly-nguoi-dung',
        activepaths: ['/quan-ly-nguoi-dung'],
        permissions: ['NGUOIDUNG', 'SUPERADMIN'],
      },
    ],
  },
  {
    label: 'Danh mục',
    key: 'danhmuc',
    icon: <i className="icon-sidebar itt-user-guide" />,
    permissions: ['COQUANQUANLY', 'DONVIHANHCHINH', 'DONVINGOAIHETHONG', 'THAMQUYENGIAIQUYET', 'LINHVUCDONTHU', 'LINHVUCDOITUONGTHANHTRA', 'NGUONDON', 'QUOCTICH', 'DANTOC', 'NOICAPGIAYTO', 'SUPERADMIN'],
    activepaths: [
      '/danh-muc-tham-quyen-giai-quyet',
      '/danh-muc-don-vi-ngoai-he-thong',
      '/danh-muc-loai-co-quan-quan-ly',
      '/danh-muc-cap-don-vi-hanh-chinh',
      '/danh-muc-don-vi-hanh-chinh',
      '/danh-muc-co-quan-quan-ly/them-moi',
      '/danh-muc-co-quan-quan-ly/chi-tiet/:id',
      '/danh-muc-co-quan-quan-ly/them-moi-cap-con/:id',
      '/danh-muc-quoc-tich',
      '/danh-muc-dan-toc',
      '/danh-muc-doi-tuong-thanh-tra',
      '/danh-muc-noi-cap-giay-to',
      '/danh-muc-huong-dan-su-dung'
    ],
    children: [
      {
        label: 'Thẩm quyền giải quyết',
        key: 'danhmucthamquyengiaiquyet',
        path: '/danh-muc-tham-quyen-giai-quyet',
        activepaths: ['/danh-muc-tham-quyen-giai-quyet'],
        permissions: permissions.THAMQUYENGIAIQUYET_XEM_DANH_SACH
      },
      {
        label: 'Đơn vị ngoài hệ thống',
        key: 'donvingoaihethong',
        path: '/danh-muc-don-vi-ngoai-he-thong',
        activepaths: ['/danh-muc-don-vi-ngoai-he-thong'],
        permissions: permissions.DONVINGOAIHETHONG_XEM_DANH_SACH

      },
      {
        label: 'Đơn vị hành chính',
        key: 'donvihanhchinh',
        path: '/danh-muc-don-vi-hanh-chinh',
        activepaths: ['/danh-muc-don-vi-hanh-chinh'],
        permissions: permissions.DONVIHANHCHINH_XEM_DANH_SACH
      },
      {
        label: 'Nguồn đơn',
        key: 'nguondon',
        path: '/danh-muc-nguon-don',
        activepaths: ['/danh-muc-nguon-don'],
        permissions: permissions.NGUONDON_XEM_DANH_SACH
      },
      {
        label: 'Quốc tịch',
        key: 'quoctich',
        path: '/danh-muc-quoc-tich',
        activepaths: ['/danh-muc-quoc-tich'],
        permissions: permissions.QUOCTICH_XEM_DANH_SACH
      },
      {
        label: 'Dân tộc',
        key: 'dantoc',
        path: '/danh-muc-dan-toc',
        activepaths: ['/danh-muc-dan-toc'],
        permissions: permissions.DANTOC_XEM_DANH_SACH
      },
      {
        label: 'Lĩnh vực đơn thư',
        key: 'linhvucdonthu',
        path: '/danh-muc-linh-vuc-don-thu',
        activepaths: ['/danh-muc-linh-vuc-don-thu'],
        permissions: permissions.LINHVUCDONTHU_XEM_DANH_SACH
      },
      {
        label: 'Nơi cấp giấy tờ',
        key: 'noicapgiayto',
        path: '/danh-muc-noi-cap-giay-to',
        activepaths: ['/danh-muc-linh-vuc-don-thu'],
        permissions: permissions.NOICAPGIAYTO_XEM_DANH_SACH
      },
      {
        label: 'Hướng dẫn sử dụng',
        key: 'huongdansudung',
        path: '/danh-muc-huong-dan-su-dung',
        activepaths: ['/danh-muc-huong-dan-su-dung'],
      },
      // Tạm ẩn
      // {
      //   label: 'Lĩnh vực đối tượng thanh tra',
      //   key: 'doituongthanhtra',
      //   path: '/danh-muc-doi-tuong-thanh-tra',
      //   activepaths: ['/danh-muc-doi-tuong-thanh-tra'],
      //   permissions: permissions.LINHVUCDOITUONGTHANHTRA_XEM_DANH_SACH
      // },
    ],
  },
];

export default menu;

