import permissions from "constants/permission";

export const routes = [
  {
    path: '/',
    componentName: 'HomePage.jsx',
    name: 'trangchu',
    privateRouter: true,
  },
  {
    path: '*',
    componentName: 'NotFoundPage.jsx',
    fetchData: 'fetchNotFoundPage',
    status: 404,
    privateRouter: false,
  },
  {
    path: '/404',
    componentName: 'NotFoundPage.jsx',
    privateRouter: false,
  },
  {
    path: '/403',
    componentName: 'NotPermission.jsx',
    privateRouter: false,
  },
  {
    path: '/signin',
    componentName: 'LoginPage.jsx',
    privateRouter: false,
  },
  {
    path: '/huong-dan-su-dung',
    componentName: 'HuongDanSuDungPage.jsx',
    privateRouter: true,
  },
  {
    path: '/example',
    componentName: 'ExamplePage.jsx',
    privateRouter: true,
  },
  // Tiếp công dân thường xuyên
  {
    path: '/tiep-cong-dan-thuong-xuyen',
    componentName: 'tiepcongdan/TiepCongDanThuongXuyen.jsx',
    privateRouter: true,
    permissions: permissions.TIEPCONGDAN_XEM_DANH_SACH,
  },
  {
    path: '/tiep-cong-dan-thuong-xuyen/them-moi/:id',
    componentName: 'tiepcongdan/ChinhSuaTCDThuongXuyen.jsx',
    type: 'create',
    privateRouter: true,
    permissions: permissions.TIEPCONGDAN_CAP_NHAT
  },
  {
    path: '/tiep-cong-dan-thuong-xuyen/them-moi',
    componentName: 'tiepcongdan/ChinhSuaTCDThuongXuyen.jsx',
    type: 'create',
    privateRouter: true,
    permissions: permissions.TIEPCONGDAN_THEM_MOI
  },
  {
    path: '/tiep-cong-dan-thuong-xuyen/chi-tiet/:id',
    componentName: 'tiepcongdan/ChinhSuaTCDThuongXuyen.jsx',
    type: 'details',
    privateRouter: true,
    permissions: permissions.TIEPCONGDAN_XEM_CHI_TIET
  },
  // Tiếp công dân định kì, đột xuất
  {
    path: '/tiep-cong-dan/:loaitiepdan',
    componentName: 'tiepcongdan/SoTiepCongDanDinhKy.jsx',
    name: 'TiepCongDan',
    privateRouter: true,
    permissions: permissions.TIEPCONGDAN_XEM_DANH_SACH,
  },
  {
    path: '/tiep-cong-dan/:loaitiepdan/:typeTCD/:id',
    componentName: 'tiepcongdan/ChiTietTCDDinhKy.jsx',
    privateRouter: true,
    permissions: permissions.TIEPCONGDAN_CAP_NHAT,
  },
  {
    path: '/tiep-cong-dan/:loaitiepdan/them-moi',
    componentName: 'tiepcongdan/ThemMoiTCDdinhky.jsx',
    privateRouter: true,
    permissions: permissions.TIEPCONGDAN_THEM_MOI,
  },
  // Xử lý giải quyết đơn
  {
    path: '/xu-ly-giai-quyet-don',
    componentName: 'xulygiaiquyetdon/DanhSachXuLyGiaiQuyetDonPage.jsx',
    privateRouter: true,
    permissions: permissions.XULYGIAIQUYET_XEM_DANH_SACH,
  },
  {
    path: '/xu-ly-giai-quyet-don/them-moi',
    componentName: 'xulygiaiquyetdon/ThemMoiDonVuViecPage.jsx',
    privateRouter: true,
    permissions: permissions.XULYGIAIQUYET_THEM_MOI
  },
  {
    path: '/xu-ly-giai-quyet-don/chi-tiet/:id',
    componentName: 'xulygiaiquyetdon/ChiTietXuLyGiaiQuyetDonPage.jsx',
    privateRouter: true,
    type: 'details',
    permissions: permissions.XULYGIAIQUYET_XEM_CHI_TIET
  },
  {
    path: '/xu-ly-giai-quyet-don/xu-ly/:id',
    componentName: 'xulygiaiquyetdon/ChiTietXuLyGiaiQuyetDonPage.jsx',
    privateRouter: true,
    permissions: permissions.XULYGIAIQUYET_CAP_NHAT
  },
  // Begin Theo dõi giám sát
  {
    path: '/theo-doi-xu-ly-giai-quyet-don',
    componentName: 'theodoigiamsat/TheoDoiXuLyGiaiQuyetDon.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_XU_LY_GIAI_QUYET_DON
  },
  {
    path: '/theo-doi-xu-ly-giai-quyet-don/:id',
    componentName: 'theodoigiamsat/TheoDoiXuLyGiaiQuyetDon.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_XU_LY_GIAI_QUYET_DON
  },
  {
    path: '/theo-doi-giam-sat',
    componentName: 'theodoigiamsat/DanhSachDonTDGS.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT
  },
  {
    path: '/theo-doi-giam-sat/chi-tiet/:id',
    componentName: 'theodoigiamsat/ChiTietDonTheoDoiDonDoc.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_XEM_CHI_TIET
  },
  {
    path: '/theo-doi-dinh-ky',
    componentName: 'theodoigiamsat/TheoDoiDinhKy.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_THEO_KY
  },
  {
    path: '/theo-doi-dinh-ky/:id',
    componentName: 'theodoigiamsat/TheoDoiDinhKy.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_THEO_KY
  },
  {
    path: '/thong-ke-don-cu',
    componentName: 'theodoigiamsat/ThongKeDonCu.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_THONG_KE_DON_CU
  },
  {
    path: '/thong-ke-don-cu/:id',
    componentName: 'theodoigiamsat/ThongKeDonCu.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_THONG_KE_DON_CU
  },
  {
    path: '/thong-ke-don-cu/ho-so-vu-viec',
    componentName: 'theodoigiamsat/DanhSachThongKeGiamSatDonCu.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_THONG_KE_DON_CU
  },
  {
    path: '/thong-ke-don-cu/ho-so-vu-viec/:id',
    componentName: 'xulygiaiquyetdon/ChiTietXuLyGiaiQuyetDonPage.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_THONG_KE_DON_CU,
    type: 'thong-ke-don-cu'
  },
  {
    path: '/theo-doi-theo-nguon-tiep-nhan',
    componentName: 'theodoigiamsat/TheoDoiGiamSatTheoNguonTiepNhan.jsx',
    privateRouter: true,
    permissions: permissions.THEODOIGIAMSAT_XU_LY_DON_THEO_NGUON,

  },
  // End theo dõi giám sát
  {
    path: '/theo-doi-thuc-hien-giai-quyet-don',
    componentName: 'theodoithuchien/DanhSachTheoDoiThucHienPage.jsx',
    privateRouter: true,
    permissions: permissions.THEODOITHUCHIEN_XEM_DANH_SACH
  },
  {
    path: '/theo-doi-thuc-hien-giai-quyet-don/:id',
    componentName: 'theodoithuchien/ChiTietTheoDoiThucHienPage.jsx',
    privateRouter: true,
    permissions: permissions.THEODOITHUCHIEN_XEM_CHI_TIET
  },
  // Begin tra cứu đơn
  {
    path: '/tra-cuu-don',
    componentName: 'tracuudon/DanhSachTraCuuDonPage.jsx',
    privateRouter: true,
    permissions: permissions.TRACUU_XEM_DANH_SACH
  },
  {
    path: '/tra-cuu-don/:id',
    componentName: 'tracuudon/ChiTietTraCuuDonPage.jsx',
    privateRouter: true,
    permissions: permissions.TRACUU_XEM_CHI_TIET
  },
  {
    path: '/tra-cuu-cong-dan',
    componentName: 'tracuudon/TraCuuCongDanPage.jsx',
    privateRouter: false,
  },
  // Trang chủ
  {
    path: '/trang-chu/xu-ly-giai-quyet-don/xu-ly/:id',
    componentName: 'xulygiaiquyetdon/ChiTietXuLyGiaiQuyetDonPage.jsx',
    privateRouter: true,
    type: 'trang-chu',
  },
  {
    path: '/trang-chu/xu-ly-giai-quyet-don/chi-tiet/:id',
    componentName: 'xulygiaiquyetdon/ChiTietXuLyGiaiQuyetDonPage.jsx',
    privateRouter: true,
    type: 'trang-chu',
  },
  {
    path: '/trang-chu/xu-ly-giai-quyet-don',
    componentName: 'xulygiaiquyetdon/DanhSachXuLyGiaiQuyetDonPage.jsx',
    privateRouter: true,
    type: 'trang-chu',
  },
  {
    path: '/trang-chu/tiep-cong-dan/:loaitiepdan/:typeTCD/:id',
    componentName: 'tiepcongdan/ChiTietTCDDinhKy.jsx',
    privateRouter: true,
    type: 'trang-chu',
  },
  {
    path: 'trang-chu/tiep-cong-dan/:loaitiepdan',
    componentName: 'tiepcongdan/SoTiepCongDanDinhKy.jsx',
    name: 'TiepCongDan',
    privateRouter: true,
    type: 'trang-chu',
  },
  {
    path: '/trang-chu/tiep-cong-dan-thuong-xuyen/chi-tiet/:id',
    componentName: 'tiepcongdan/ChinhSuaTCDThuongXuyen.jsx',
    type: 'trang-chu',
    privateRouter: true,
  },
  {
    path: 'trang-chu/tiep-cong-dan-thuong-xuyen/',
    componentName: 'tiepcongdan/TiepCongDanThuongXuyen.jsx',
    privateRouter: true,
    type: 'trang-chu',
  },
  {
    path: 'trang-chu/tiep-cong-dan/',
    componentName: 'tiepcongdan/SoTiepCongDan.jsx',
    privateRouter: true,
    type: 'trang-chu',
  },
  {
    path: 'trang-chu/tiep-cong-dan/chi-tiet/:id',
    componentName: 'tiepcongdan/ChiTietTiepCongDan.jsx',
    privateRouter: true,
    type: 'trang-chu',
  },
  {
    path: '/trang-chu/xu-ly-giai-quyet-don/:trangThai',
    componentName: 'xulygiaiquyetdon/DanhSachXuLyGiaiQuyetDonPage.jsx',
    privateRouter: true,
    type: 'trang-chu',
  },
  {
    path: '/trang-chu/xu-ly-giai-quyet-don/:trangThai/:donTreHan',
    componentName: 'xulygiaiquyetdon/DanhSachXuLyGiaiQuyetDonPage.jsx',
    privateRouter: true,
    type: 'trang-chu',
  },
  // Danh mục vai trò
  {
    path: '/danh-muc-vai-tro',
    componentName: 'quantrihethong/DanhMucVaiTro.jsx',
    privateRouter: true,
    permissions: permissions.VAITRO_XEM_DANH_SACH
  },
  {
    path: '/danh-muc-vai-tro/chi-tiet/:id',
    componentName: 'quantrihethong/DanhMucVaiTroChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.VAITRO_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/danh-muc-vai-tro/them-moi',
    componentName: 'quantrihethong/DanhMucVaiTroChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.VAITRO_THEM_MOI,
    type: 'create',
  },
  {
    path: 'phan-quyen-vai-tro',
    componentName: 'quantrihethong/PhanQuyenVaiTro.jsx',
    privateRouter: true,
    permissions: permissions.QUYEN_XEM_DANH_SACH,
  },
  // Danh mục thâm quyền giải quyết
  {
    path: '/danh-muc-tham-quyen-giai-quyet',
    componentName: 'quanlydanhmuc/thamquyengiaiquyet/ThamQuyenGiaiQuyet.jsx',
    privateRouter: true,
    permissions: permissions.THAMQUYENGIAIQUYET_XEM_DANH_SACH,
  },
  {
    path: '/danh-muc-tham-quyen-giai-quyet/chi-tiet/:id',
    componentName: 'quanlydanhmuc/thamquyengiaiquyet/ThamQuyenGiaiQuyetChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.THAMQUYENGIAIQUYET_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/danh-muc-tham-quyen-giai-quyet/them-moi',
    componentName: 'quanlydanhmuc/thamquyengiaiquyet/ThamQuyenGiaiQuyetChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.THAMQUYENGIAIQUYET_THEM_MOI,
    type: 'create',
  },
  // Danh mục đơn vị ngoài hệ thống
  {
    path: '/danh-muc-don-vi-ngoai-he-thong',
    componentName: 'quanlydanhmuc/donvingoaihethong/DonViNgoaiHeThong.jsx',
    privateRouter: true,
    permissions: permissions.DONVINGOAIHETHONG_XEM_DANH_SACH,
  },
  {
    path: '/danh-muc-don-vi-ngoai-he-thong/chi-tiet/:id',
    componentName: 'quanlydanhmuc/donvingoaihethong/DonViNgoaiHeThongChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.DONVINGOAIHETHONG_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/danh-muc-don-vi-ngoai-he-thong/them-moi',
    componentName: 'quanlydanhmuc/donvingoaihethong/DonViNgoaiHeThongChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.DONVINGOAIHETHONG_THEM_MOI,
    type: 'create',
  },
  // Danh mục loại cơ quan quản lý
  {
    path: '/danh-muc-loai-co-quan-quan-ly',
    componentName: 'quanlydanhmuc/loaicoquanquanly/LoaiCoQuanQuanLy.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_XEM_DANH_SACH,
  },
  {
    path: '/danh-muc-loai-co-quan-quan-ly/chi-tiet/:id',
    componentName: 'quanlydanhmuc/loaicoquanquanly/LoaiCoQuanQuanLyChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/danh-muc-loai-co-quan-quan-ly/them-moi',
    componentName: 'quanlydanhmuc/loaicoquanquanly/LoaiCoQuanQuanLyChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_THEM_MOI,
    type: 'create',
  },
  // Danh mục cấp đơn vị hành chính
  {
    path: '/danh-muc-cap-don-vi-hanh-chinh',
    componentName: 'quanlydanhmuc/capdonvihanhchinh/CapDonViHanhChinh.jsx',
    privateRouter: true,
    permissions: permissions.DONVIHANHCHINH_XEM_DANH_SACH,
  },
  {
    path: '/danh-muc-cap-don-vi-hanh-chinh/chi-tiet/:id',
    componentName: 'quanlydanhmuc/capdonvihanhchinh/CapDonViHanhChinhChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.DONVIHANHCHINH_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/danh-muc-cap-don-vi-hanh-chinh/them-moi',
    componentName: 'quanlydanhmuc/capdonvihanhchinh/CapDonViHanhChinhChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.DONVIHANHCHINH_THEM_MOI,
    type: 'create',
  },
  // Danh mục cấp cơ quan quản lý
  {
    path: '/danh-muc-cap-co-quan-quan-ly',
    componentName: 'quanlydanhmuc/capcoquanquanly/CapCoQuanQuanLy.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_XEM_DANH_SACH
  },
  {
    path: '/danh-muc-cap-co-quan-quan-ly/chi-tiet/:id',
    componentName: 'quanlydanhmuc/capcoquanquanly/CapCoQuanQuanLyChiTiet.jsx',
    privateRouter: true,
    type: 'details',
    permissions: permissions.COQUANQUANLY_CAP_NHAT
  },
  {
    path: '/danh-muc-cap-co-quan-quan-ly/them-moi',
    componentName: 'quanlydanhmuc/capcoquanquanly/CapCoQuanQuanLyChiTiet.jsx',
    privateRouter: true,
    type: 'create',
    permissions: permissions.COQUANQUANLY_THEM_MOI
  },
  {
    path: '/danh-muc-cap-co-quan-quan-ly/them-moi-cap-con/:id',
    componentName: 'quanlydanhmuc/capcoquanquanly/CapCoQuanQuanLyChiTiet.jsx',
    privateRouter: true,
    type: 'createChild',
    permissions: permissions.COQUANQUANLY_CAP_NHAT
  },
  // Danh mục quốc tịch
  {
    path: '/danh-muc-quoc-tich',
    componentName: 'quanlydanhmuc/quoctich/QuocTich.jsx',
    privateRouter: true,
    permissions: permissions.QUOCTICH_XEM_DANH_SACH,
  },
  {
    path: '/danh-muc-quoc-tich/chi-tiet/:id',
    componentName: 'quanlydanhmuc/quoctich/QuocTichChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.QUOCTICH_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/danh-muc-quoc-tich/them-moi',
    componentName: 'quanlydanhmuc/quoctich/QuocTichChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.QUOCTICH,
    type: 'create',
  },
  // Danh mục dân tộc
  {
    path: '/danh-muc-dan-toc',
    componentName: 'quanlydanhmuc/dantoc/DanToc.jsx',
    privateRouter: true,
    permissions: permissions.DANTOC_XEM_DANH_SACH,
  },
  {
    path: '/danh-muc-dan-toc/chi-tiet/:id',
    componentName: 'quanlydanhmuc/dantoc/DanTocChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.DANTOC_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/danh-muc-dan-toc/them-moi',
    componentName: 'quanlydanhmuc/dantoc/DanTocChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.DANTOC_THEM_MOI,
    type: 'create',
  },
  // Danh mục đơn vị hành chính
  {
    path: '/danh-muc-don-vi-hanh-chinh',
    componentName: 'quanlydanhmuc/donvihanhchinh/DonViHanhChinh.jsx',
    privateRouter: true,
    permissions: permissions.DONVIHANHCHINH,
  },
  {
    path: '/danh-muc-don-vi-hanh-chinh/chi-tiet/:id',
    componentName: 'quanlydanhmuc/donvihanhchinh/DonViHanhChinhChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.DONVIHANHCHINH,
    type: 'details',
  },
  {
    path: '/danh-muc-don-vi-hanh-chinh/them-moi',
    componentName: 'quanlydanhmuc/donvihanhchinh/DonViHanhChinhChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.DONVIHANHCHINH,
    type: 'create',
  },
  {
    path: '/danh-muc-don-vi-hanh-chinh/them-moi-cap-con/:id',
    componentName: 'quanlydanhmuc/donvihanhchinh/DonViHanhChinhChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.DONVIHANHCHINH,
    type: 'createChild',
  },
  // Thống kê báo cáo thuộc thẩm quyền giải quyết khiếu nại
  {
    path: '/tkbc-giai-quyet-khieu-nai-thuoc-tham-quyen',
    componentName: 'tonghopbaocao/tkbcthuocthamquyengiaiquyetkhieunai/TkbcThuocThamQuyenGiaiQuyetKhieuNai.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON,
  },
  // Thống kê báo cáo xử lý đơn
  {
    path: '/tkbc-xu-ly-don',
    componentName: 'tonghopbaocao/tkbcxulydon/TkbcXuLyDon.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_TIEP_CONG_DAN,
  },
  // Thống kê báo cáo phân loại xử lý qua tiếp công dân
  {
    path: '/tkbc-phan-loai-xu-ly-qua-tiep-cong-dan',
    componentName: 'tonghopbaocao/tkbcphanloaixulydonquatiepcongdan/ThongKeBaoCaoPhanLoaiXuLyQuaTCD.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON,
  },
  // Danh mục cơ quan quản lý
  {
    path: '/danh-muc-co-quan-quan-ly',
    componentName: 'quanlydanhmuc/coquanquanly/CoQuanQuanLy.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_XEM_DANH_SACH,
  },
  {
    path: '/danh-muc-co-quan-quan-ly/:id',
    componentName: 'quanlydanhmuc/coquanquanly/CoQuanQuanLy.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_XEM_DANH_SACH
  },
  {
    path: '/danh-muc-co-quan-quan-ly-cap-con',
    componentName: 'quanlydanhmuc/coquanquanly/CoQuanQuanLyCapCon.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_XEM_DANH_SACH,
  },
  {
    path: '/danh-muc-co-quan-quan-ly-cap-chau',
    componentName: 'quanlydanhmuc/coquanquanly/CoQuanQuanLyCapChau.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_XEM_DANH_SACH,
  },
  {
    path: '/danh-muc-co-quan-quan-ly/them-moi',
    componentName: 'quanlydanhmuc/coquanquanly/CoQuanQuanLyChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_THEM_MOI,
    type: 'create',
  },
  {
    path: '/danh-muc-co-quan-quan-ly/chi-tiet/:id',
    componentName: 'quanlydanhmuc/coquanquanly/CoQuanQuanLyChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/danh-muc-co-quan-quan-ly-cap-con/chi-tiet/:id',
    componentName: 'quanlydanhmuc/coquanquanly/CoQuanQuanLyChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/danh-muc-co-quan-quan-ly-cap-chau/chi-tiet/:id',
    componentName: 'quanlydanhmuc/coquanquanly/CoQuanQuanLyChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.COQUANQUANLY_CAP_NHAT,
    type: 'details',
  },
  // Danh mục nguồn đơn
  {
    path: '/danh-muc-nguon-don',
    componentName: 'quanlydanhmuc/nguondon/NguonDon.jsx',
    privateRouter: true,
    permissions: permissions.NGUONDON,
  },
  {
    path: '/danh-muc-nguon-don/chi-tiet/:id',
    componentName: 'quanlydanhmuc/nguondon/NguonDonChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.NGUONDON,
    type: 'details',
  },
  {
    path: '/danh-muc-nguon-don/them-moi',
    componentName: 'quanlydanhmuc/nguondon/NguonDonChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.NGUONDON,
    type: 'create',
  },
  // Danh mục lĩnh vực đơn thư
  {
    path: '/danh-muc-linh-vuc-don-thu',
    componentName: 'quanlydanhmuc/linhvucdonthu/LinhVucDonThu.jsx',
    privateRouter: true,
    permissions: permissions.LINHVUCDONTHU,
  },
  {
    path: '/danh-muc-linh-vuc-don-thu/chi-tiet/:id',
    componentName: 'quanlydanhmuc/linhvucdonthu/LinhVucDonThuChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.LINHVUCDONTHU,
    type: 'details',
  },
  {
    path: '/danh-muc-linh-vuc-don-thu/them-moi',
    componentName: 'quanlydanhmuc/linhvucdonthu/LinhVucDonThuChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.LINHVUCDONTHU,
    type: 'create',
  },
  {
    path: '/danh-muc-linh-vuc-don-thu/them-moi-cap-con/:id',
    componentName: 'quanlydanhmuc/linhvucdonthu/LinhVucDonThuChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.LINHVUCDONTHU,
    type: 'createChild',
  },
  // Quản lý ngừoi dùng
  {
    path: '/quan-ly-nguoi-dung',
    componentName: 'quantrihethong/QuanLyNguoiDung.jsx',
    privateRouter: true,
    permissions: permissions.NGUOIDUNG,
  },
  {
    path: '/quan-ly-nguoi-dung/chi-tiet/:id',
    componentName: 'quantrihethong/QuanLyNguoiDungChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.NGUOIDUNG_CAP_NHAT,
    type: 'details',
  },
  {
    path: '/quan-ly-nguoi-dung/them-moi',
    componentName: 'quantrihethong/QuanLyNguoiDungChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.NGUOIDUNG_THEM_MOI,
    type: 'create',
  },
  // Danh mục nơi cấp giấy tờ
  {
    path: '/danh-muc-noi-cap-giay-to',
    componentName: 'quanlydanhmuc/noicapgiayto/NoiCapGiayTo.jsx',
    privateRouter: true,
    permissions: permissions.NOICAPGIAYTO,
  },
  {
    path: '/danh-muc-noi-cap-giay-to/chi-tiet/:id',
    componentName: 'quanlydanhmuc/noicapgiayto/NoiCapGiayToChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.NOICAPGIAYTO,
    type: 'details',
  },
  {
    path: '/danh-muc-noi-cap-giay-to/them-moi',
    componentName: 'quanlydanhmuc/noicapgiayto/NoiCapGiayToChiTiet.jsx',
    privateRouter: true,
    permissions: permissions.NOICAPGIAYTO,
    type: 'create',
  },
  // Danh mục hướng dẫn sử dụng
  {
    path: '/danh-muc-huong-dan-su-dung',
    componentName: 'HuongDanSuDungPage.jsx',
    privateRouter: true,
  },

  // Thống kê báo cáo tiếp công dân thường xuyên
  {
    path: '/tkbc-tiep-cong-dan-thuong-xuyen',
    componentName: 'tonghopbaocao/TkbcTiepCongDanThuongXuyen.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_TIEP_CONG_DAN,
  },
  // Thống kê báo cáo xử lý đơn kiếu nại
  {
    path: '/tkbc-xu-ly-don-khieu-nai',
    componentName: 'tonghopbaocao/TkbcXuLyDonKhieuNai.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON,
  },
  // Thống kê báo cáo xử lý đơn tố cáo
  {
    path: '/tkbc-xu-ly-don-to-cao',
    componentName: 'tonghopbaocao/TkbcXuLyDonToCao.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON,
  },
  // Thống kê xử lý đơn kiến nghị, phản ánh
  {
    path: '/tkbc-xu-ly-don-kien-nghi',
    componentName: 'tonghopbaocao/TkbcXuLyDonKienNghi.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON,
  },
  // Thống kê báo cáo thi hành quyết định giải quyết khiếu nại
  {
    path: '/tkbc-thi-hanh-quyet-dinh-giai-quyet-khieu-nai',
    componentName: 'tonghopbaocao/TkbcThiHanhQuyetDinhGiaiQuyet.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON,
  },
  // Thống kê báo cáo giải quyết tố cáo thuộc thẩm quyền
  {
    path: '/tkbc-giai-quyet-to-cao-thuoc-tham-quyen',
    componentName: 'tonghopbaocao/TkbcGiaiQuyetToCaoThuocThamQuyen.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON,
  },
  // Thống kê báo cáo thực hiện kết luận nội dung tố cáo
  {
    path: '/tkbc-thuc-hien-ket-luan-noi-dung-bao-cao',
    componentName: 'tonghopbaocao/tkbcthuchiennoidungketluantocao/TkbcThucHienKetLuanNoiDungToCao.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON,
  },
  // Thống kê báo cáo so sánh tình hình xử lý đơn qua từng năm
  {
    path: '/tkbc-bao-cao-so-sanh-tinh-hinh-xu-ly-don-qua-tung-nam',
    componentName: 'tonghopbaocao/TkbcBaoCaoXuLyDonQuaTungNam.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_TIEP_CONG_DAN_VA_XU_LY_DON,
  },
  // Thống kê báo cáo công tác tiếp công dân và xử lý đơn
  {
    path: '/tkbc-cong-tac-tiep-cong-dan-va-xu-ly-don',
    componentName: 'tonghopbaocao/TkbcTiepCongDanVaXuLyDon.jsx',
    privateRouter: true,
    permissions: permissions.THONGKEBAOCAO_TIEP_CONG_DAN_VA_XU_LY_DON,
  },
  // Danh mục chức vụ
  {
    path: '/danh-muc-chuc-vu',
    componentName: 'quantrihethong/ChucVu.jsx',
    privateRouter: true,
  },
  {
    path: '/danh-muc-chuc-vu/chi-tiet/:id',
    componentName: 'quantrihethong/ChucVuChiTiet.jsx',
    privateRouter: true,
    type: 'details',
  },
  {
    path: '/danh-muc-chuc-vu/them-moi',
    componentName: 'quantrihethong/ChucVuChiTiet.jsx',
    privateRouter: true,
    type: 'create',
  },
  // Tạm ẩn
  // Danh mục đối tượng thanh tra
  // {
  //   path: '/danh-muc-doi-tuong-thanh-tra',
  //   componentName: 'quanlydanhmuc/doituongthanhtra/DoiTuongThanhTra.jsx',
  //   privateRouter: true,
  // },
  // {
  //   path: '/danh-muc-doi-tuong-thanh-tra/chi-tiet/:id',
  //   componentName: 'quanlydanhmuc/doituongthanhtra/DoiTuongThanhTraChiTiet.jsx',
  //   privateRouter: true,
  //   type: 'details',
  // },
  // {
  //   path: '/danh-muc-doi-tuong-thanh-tra/them-moi',
  //   componentName: 'quanlydanhmuc/doituongthanhtra/DoiTuongThanhTraChiTiet.jsx',
  //   privateRouter: true,
  //   type: 'create',
  // },
];